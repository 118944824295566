import React from "react";
// components
import { DefaultLayout } from "../components/layout/defaultLayout";
import { PageHeading } from "../components/heading/pageHeading";
import { BreadcrumbBar } from "../components/bar/breadcrumbBar";
import { BrandSection } from "../components/section/brandSection";
// breadcrumb
const breadcrumbs = [
  { txt: "トップ", url: "/" },
  { txt: "ブランドから探す", url: "/brand" },
];

const BrandPage = () => {
  return (
    <DefaultLayout pageTitle="ブランドから探す">
      <PageHeading enTxt="BRAND" jpTxt="ブランドから探す" />
      <BreadcrumbBar breadcrumbs={breadcrumbs} />
      <BrandSection />
    </DefaultLayout>
  );
};

export default BrandPage;
